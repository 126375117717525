import './Styles/global.planrv.less';
import './Files';

import './Setup';
import './Components';

import { default as context } from '@truckdown/systems';
import { IAppUserContext } from './IAppUserContext';

export type { IAppUserContext };
export { IconType } from './IconType';
export type { IIconImport } from './IIconImport';
export default context as IAppUserContext;
